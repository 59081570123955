import React from 'react'
import Insta from '../pics/icons/instagram-round-line-icon.png'
import Twitter from '../pics/icons/x-logo.png'
import Facebook from '../pics/icons/facebook-line-icon.png'
import Linkedin from '../pics/icons/linkedin-line-icon.png'

import InstaWhite from '../pics/icons/instagram-round-line-icon-white.png'
import TwitterWhite from '../pics/icons/x-logo-white.png'
import FacebookWhite from '../pics/icons/facebook-line-icon-white.png'
import LinkedinWhite from '../pics/icons/linkedin-line-icon-white.png'

export default function SocialMediaLinks({color}){
	return <section className="social">
		<a className='socialMediaImagesLinks' target='_blank' href="http://instagram.com/barcelonacodeschool">
		<img src={color==='white'?InstaWhite:Insta} alt='Barcelona Code School Instagram'/>
		</a>
		<a className='socialMediaImagesLinks' target='_blank' href="https://www.linkedin.com/school/barcelona-code-school">
		<img src={color==='white'? LinkedinWhite:Linkedin} alt='Barcelona Code School Linkedin'/>
		</a>
		<a className='socialMediaImagesLinks' target='_blank' href="https://twitter.com/bcncodeschool">
		<img src={color==='white'? TwitterWhite:Twitter} alt='Barcelona Code School Twitter'/>
		</a>
		<a className='socialMediaImagesLinks' target='_blank' href="https://www.facebook.com/BarcelonaCodingSchool/">
		<img src={color==='white'? FacebookWhite:Facebook} alt='Barcelona Code School Facebook'/>
		</a>
		</section>
}

