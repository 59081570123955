import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'

const Consent = ({allConsentGranted}) => {
    const [consentGiven, setConsentGiven] = useState(null);
    const [okToRender, setOkToRender] = useState(false);


    useEffect(() => {
        const consent = localStorage.getItem('consent');
        if (consent) {
            setConsentGiven(true);
            allConsentGranted()
        }
        setOkToRender(true);
    }
    , []);

    const handleConsent = () => {
        setConsentGiven(true);
        localStorage.setItem('consent', 'true');
        allConsentGranted()
    };

    const handleNoConsent = () => {
        setConsentGiven(false);
        sessionStorage.setItem('consent', 'false');
    };

    return okToRender ? <div >
            {consentGiven===null && (
                <div className='transparentContainer' style={styles.consentContainer}>
                    <div >
                        <p style={styles.consentText}>We need your consent</p>
                        <p style={styles.consentText}>We use cookies on this website to make your browsing experience better.  
                        <Link
							to="/about-us/privacy-policy#cookies"
							style={styles.consentLink}
						> Learn more</Link>
                        </p>
                    </div>
                    <div>
                    <button onClick={handleConsent} style={styles.consentButton}>Accept</button>
                    <p onClick={handleNoConsent} className='whiteButton' style={styles.consentButtonNo}>Deny</p>
                    </div>
                </div>
            )}
        </div>
    : null
};

const styles = {
    consentContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width:'fit-content',
        maxWidth: '100%',
        margin: '1em auto',
        zIndex: 999999,
        fontSize: 'small',
        backgroundColor: 'rgba(255, 255, 255, 95%)'
    },
    consentButton: {
        maxWidth: 'fit-content',
        height: '2.5em',
        padding:'0.5em'
    },
    consentButtonNo: {
        marginTop:'0.5em',
        border: '1px solid rgb(162 162 162)',
        color:'rgb(162 162 162)',
        height: '2.5em',
        padding:'0.5em'
    },
    consentText: {
        color: '#333'
    },
    consentLink: {
        color: '#333',
        display: 'inline',
        fontSize: 'inherit',
        textDecoration: 'none',
        marginRight: '1em',
    }
};

export default Consent;